.Placeholder {
  background-image: url(./SN_Website2019_GIF_423.gif);
  background-size: contain;
  background-position-x: center;
  height: 250px;
  width: 250px;
  display: grid;
  border-radius: 5px;
}

.UploadedImage {
  max-width: 250px;
}

@media only screen and (min-width: 700px) {
  .Placeholder {
    height: 423px;
    width: 423px;
  }
  .UploadedImage {
    max-width: 423px;
  }
}

.Placeholder .UploadButton {
  background-color: #fb5524;
  border: 0px;
  border-radius: 30px;
  margin: auto;
  font-size: 1em;
  height: 50px;
}

.LoadingSpinner {
  margin: auto;
}

.UploadButton span {
  opacity: 0.8;
}
