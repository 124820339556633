.Form span.ant-input-group-addon {
  color: var(--blue-1) !important;
}

.App h1 {
  color: #fff;
}

.Form h3 {
  color: #fff;
  text-align: center;
  font-size: 18px;
}

.Form p {
  font-size: 22px;
  color: #fff;
}

p.FormError {
  font-size: 16px;
  color: #f5222d;
  margin: 0px;
}

.Container {
  padding: 0px 10px;
  width: 100%;
  min-width: 340px;
}

.App {
  margin: 20px auto;
  padding: 20px 20px;
  border-radius: 20px;
  background-color: var(--purple-1);
  display: grid;
  max-width: 850px;
  width: 100%;
}

/* .BottomImage {
  background-image: url(./artist-image-1-crop.png);
  background-size: cover;
  height: 460px;
  border-radius: 10px;
  margin: 30px 0px;
  display: none;
} */

.FileRequestCTAContainer {
  margin: 20px;
  width: calc(100% - 40px);
  border-radius: 20px;
  background-image: url(./upload-image-placeholder.png);
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}

.FileRequestCTA {
  height: 250px;
  display: grid;
  border-radius: 5px;
}

@media only screen and (min-width: 700px) {
  .FileRequestCTA {
    height: 423px;
  }
}

@media only screen and (min-width: 1200px) {
  .FileRequestCTA {
    height: 600px;
  }
}

.FileRequestCTA a {
  display: grid;
}

.FileRequestCTA button {
  background-color: #fb5524;
  border: 0px;
  border-radius: 30px;
  margin: auto;
  font-size: 1em;
  height: 50px;
  color: #fff;
  cursor: pointer;
  padding-left: 35px;
  padding-right: 35px;
}

.ConfirmationPage {
  display: grid;
  margin: auto;
}

.ConfirmationPage a {
  color: #fff;
}

.Form {
  display: grid;
  grid-template-columns: 1fr;
}

.FormSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin: 10px 0px;
}

.FormSectionOne {
  composes: FormSection;
  grid-template-rows: 27px 34px 34px 34px 27px;
}

.FormSectionTwo {
  composes: FormSection;
  grid-template-rows: 27px 34px 34px 34px 27px;
}

.Form h3 {
  margin: 0px;
}

.Form input {
  font-size: 16px;
}

.Form textarea {
  font-size: 16px;
}

.Form button {
  font-size: 16px;
}

.Form ::placeholder {
  color: var(--blue-1);
}

.ShowField {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.Submit {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  margin-top: 20px;
  align-items: center;
  justify-self: center;
}

.Submit button {
  background-color: var(--blue-1);
  color: #fff;
  border: 0px;
  border-radius: 30px;
  font-size: 1.5em;
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;
}

.Submit button:hover {
  background-color: #fff;
  color: #000;
  border-color: #000;
}

.Submit p {
  font-size: 0.7em;
  max-width: 200px;
  margin: 0px;
}

.RequiredAsterisk {
  color: #f5222d;
  font-size: 1.3em;
}

.UploadArtistImage {
  margin: auto;
}

.Footer {
  margin-top: 25px;
  display: grid;
  justify-items: center;
}

@media only screen and (min-width: 550px) {
  .Container {
    padding: 0px 20px;
    width: 100%;
  }

  .App {
    margin: 40px auto;
    padding: 30px 50px;
    border-radius: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .Form {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
  }
  .FormSection {
    margin: 0px;
  }
  .Section1Desc {
    grid-row: 2;
  }
  .UploadArtistImage {
    margin-top: 30px;
  }
  .App {
    max-width: unset;
  }
  /* .BottomImage {
    display: block;
  } */
  .Footer {
    justify-items: left;
  }
  .Submit {
    margin-top: 0px;
  }
}
