.ant-form-explain {
  text-align: left;
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-upload.ant-upload-select-picture-card {
  margin: 0px;
  background-color: unset;
  border: unset;
}

.ant-input {
  border-radius: 6px;
  font-size: 16px !important;
}

.anticon.anticon-calendar.ant-calendar-picker-icon {
  display: none;
}

.ant-form-item-control {
  line-height: 0px;
}
